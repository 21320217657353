<template>
 <eden-confirm-dialog
    title="Delete Menu"
    button-type="danger"
    button-text="Delete"
    :show.sync="shouldShow"
    @confirm="deleteMenu"
  >
    <p>
      Are you sure you want to delete this menu?
    </p>
  </eden-confirm-dialog>
  </template>
  
  <script>

  import onetime from "@/requests/services/meal/onetime/index";
  
  export default {
    name: "OneTimeMenuDelete",
    props: {
      show: {
      type: Boolean,
      default: false
    },
    menuId: {
      type: [Number, String],
      required: true
    }
    },
    data() {
      return {
        deleting: false,
      };
    },
    computed: {
      shouldShow: {
        get() {
          return this.show;
        },
        set(value) {
          this.$emit("update:show", value);
        },
      },
    },
    methods: {
      closeEvent() {
        this.shouldShow = false;
        this.deleting = false;
      },
      deleteMenu() {
        onetime
        .delete(this.menuId)
        .then(response => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success", this.menuId);
            this.shouldShow = false;
          }
        })
        .catch(error => {
          this.$message.error(error.response.data.message);
          this.shouldShow = false;
        });
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  p {
    line-height: 2;
  }
  </style>
  