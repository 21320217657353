<template>
  <div>
    <eden-table-actions :title="title" @search="searchItems">
      <template slot="actions" v-if="allowAccessFor(['superadmin', 'admin', 'operations'])">
        <div class="working-hour">
          <p>Store Closes by {{ formattedWorkingHour }}</p>
        </div>
        <el-time-select v-model="storeClose12HrFormat" class="text-cursor mr-2" :picker-options="{
          start: '00:00',
          end: '23:59',
        }" placeholder="Close Store By" :clearable="false" @change="setClosingHours">
        </el-time-select>
        <el-dropdown @command="menuChange">
          <el-button class="text-cursor">
            {{ menuTypes[menuType] }} <i class="eden-icon-arrow-down"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(value, key, i) in menuTypes" :key="i" :command="key"
              :class="{ selected: key === menuType }">{{ value }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-button type="primary" class="ml-10" @click="addNewMenu">Add New Menu</el-button>
      </template>
    </eden-table-actions>
    <div v-if="loading">Loading...</div>
    <div v-else>
      <el-table :data="sortedItems">
        <el-table-column min-width="300">
          <template slot="header">
            <span>Name</span>
          </template>
          <template v-slot="scope">
            <div class="text-cursor" @click="menu(scope.row.id, 'menu')">
              <span>{{ scope.row.name }}</span>
              <el-tag v-if="scope.row.is_active == 1" :type="setType('current')">
                Active Menu
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="200">
          <template slot="header">
            <span>Number of Meals</span>
          </template>
          <template v-slot="scope">
            <p>{{ scope.row.total_meal_item }}</p>
          </template>
        </el-table-column>
        <el-table-column min-width="200">
          <template slot="header">
            <span>Status</span>
          </template>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.is_draft == 1" :type="setType('draft')">
              Draft
            </el-tag>
            <el-tag v-else :type="setType('published')"> Published </el-tag>
          </template>
        </el-table-column>
        <el-table-column v-if="allowAccessFor(['superadmin', 'admin', 'operations', 'kitchen'])
          " width="80">
          <template v-slot="scope">
            <el-dropdown @command="command" class="more">
              <span class="el-dropdown-link">
                <i class="eden-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="eden-icon-edit" :command="{
                  action: 'edit',
                  id: scope.row.id,
                  index: scope.$index,
                }">Edit menu</el-dropdown-item>
                <el-dropdown-item icon="el-icon-link" :command="{
                  action: 'duplicate',
                  id: scope.row.id,
                  index: scope.$index,
                }">Duplicate menu</el-dropdown-item>
                <el-dropdown-item v-if="allowAccessFor(['superadmin', 'operations', 'kitchen']) &&
                  scope.row.is_draft !== 1
                  " icon="el-icon-document" :command="{
    action: 'make-active',
    id: scope.row.id,
    index: scope.$index,
  }">Make active menu</el-dropdown-item>
                <el-dropdown-item v-if="allowAccessFor(['superadmin', 'operations', 'kitchen']) &&
                  scope.row.is_draft === 1
                  " icon="el-icon-document" :command="{
    action: 'publish-menu',
    id: scope.row.id,
    index: scope.$index,
  }">Publish menu</el-dropdown-item>
                <el-dropdown-item icon="eden-icon-delete" :command="{
                  action: 'delete',
                  id: scope.row.id,
                  index: scope.$index,
                }">Delete menu</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <eden-pagination v-if="pageData.length" :page-size="pageSize" :from="from" :to="to" :total="total"
        :current-page.sync="page" />
      <one-time-menu-delete :show.sync="showMenuDelete" :menu-id="menuId" @success="removeMenu" />
    </div>
  </div>
</template>

<script>
import OneTimeMenuDelete from "@/components/Services/Meal/MealOneTimeOrder/OneTimeMenuDelete";
import onetime from "@/requests/services/meal/onetime/index";
import * as actions from "@/store/action-types";

export default {
  name: "OneTimeMenu",
  components: {
    OneTimeMenuDelete,
  },
  data() {
    return {
      loading: false,
      closing_hour: "",
      working_hour: "",
      page: 1,
      pageData: [],
      showPagination: true,
      showSelectionWindow: false,
      showMenuDelete: false,
      showCalendar: false,
      dateSet: "All Time",
      menuType: "all",
      menuId: "",
      menuTypes: {
        published: "Published Only",
        all: "All Menus",
        draft: "Drafts Only",
      },
      total: "",
      pagination: {
        from: null,
        to: null,
        total: null,
      },
    };
  },
  computed: {
    storeClose12HrFormat: {
      get() {
        if (!this.closing_hour || !this.closing_hour.includes(':')) {
          return;
        }
        const [hour, minute] = this.closing_hour.split(':');
        let period = 'AM';
        let adjustedHour = hour;

        if (hour > 12) {
          adjustedHour = hour - 12;
          period = 'PM';
        } else if (hour == 0) {
          adjustedHour = 12;
        }

        return `${String(adjustedHour).padStart(2, '0')}:${minute} ${period}`;
      },
      set(value) {
        // Convert 12Hr format back to 24Hr format for storing
        const [hour, minute, period] = value.split(/[: ]/);

        let adjustedHour = hour;

        if (period === 'PM' && hour != 12) {
          adjustedHour = parseInt(hour) + 12;
        } else if (period === 'AM' && hour == 12) {
          adjustedHour = 0;
        }

        this.closing_hour = `${String(adjustedHour).padStart(2, '0')}:${minute}`;
      }
    },
    formattedWorkingHour() {
      if (!this.working_hour) return '';
      const timeRegex = /(\d{1,2}:\d{2}\s*[apmAPM]{2})/;
      const match = this.working_hour.closing_hour.match(timeRegex);
      return match ? match[1] : '';
    },
    service() {
      return this.$route.params.service;
    },
    title() {
      return `${this.loading ? 0 : this.total} One-time Order Menus`;
    },
    sortedItems() {
      let sortData = this.pageData.slice();
      return sortData.sort((a, b) => {
        if (a.is_draft === 1) return -1;
        if (b.is_draft === 1) return 1;
        return 0;
      });
    },
    mealItems() {
      return this.$store.getters.meal_item_list;
    },
  },
  watch: {
    page() {
      this.getMenus();
    },
    menuType() {
      this.filter();
    },
  },
  created() {
    this.getMenus();
    this.getClosingHrs();
    if (this.mealItems.length === 0) {
      this.getItems();
    }
  },
  methods: {
    setdate(value) {
      this.dateSet = value;
    },
    addNewMenu() {
      this.$route.params.service = "one-time-order-menu";
      this.$router.push({ name: "services.service-type.add" });
    },
    menuChange(menuType) {
      this.menuType = menuType;
    },
    setClosingHours() {
      this.loading = true;
      let payload = {
        closing_hour: this.storeClose12HrFormat
      }
      onetime
        .closingHrs(payload)
        .then((response) => {
          this.$message.success(response.data.message);
          this.getClosingHrs()
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.response.data.message);
        });
    },
    getClosingHrs() {
      this.loading = true;
      onetime
        .getClosingHrs()
        .then((response) => {
          this.working_hour = response.data.data
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.response.data.message);
        });
    },
    filter() {
      this.loading = true;
      if (this.menuType === "all") {
        this.getMenus();
      } else {
        onetime
          .filter(this.menuType)
          .then((response) => {
            const { status, data } = response.data;
            if (status) {
              this.pageData = data.data;
              this.from = data.from;
              this.to = data.to;
              this.total = data.total;
              this.pageSize = data.per_page;
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.$message.error(error.response.data.message);
          });
      }
    },
    command(command) {
      this.menuId = parseInt(command.id);
      switch (command.action) {
        case "view":
          this.menu(command.id, "menu");
          break;
        case "edit":
          this.menu(command.id, "edit");
          // this.searchItems();
          break;
        case "duplicate":
          this.duplicate();
          // this.menu(command.id, "menu-duplicate");
          break;
        case "make-active":
          this.setMenuActive();
          break;
          case "publish-menu":
          this.publishMenu();
          break;
        case "delete":
          this.showMenuDelete = true;
          break;
      }
    },
    async getItems() {
      await this.$store
        .dispatch(actions.GET_MEAL_ITEM_LIST)
        .then(() => { })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        });
    },
    getMenus(params = "") {
      this.loading = true;
      const paramsPage = params + `page=${this.page}`;
      onetime
        .list(paramsPage)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.pageData = data.data;
            this.from = data.from;
            this.to = data.to;
            this.total = data.total;
            this.pageSize = data.per_page;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.response.data.message);
        });
    },
    menu(id, action) {
      this.$router.push({
        name: `services.one-time.${action}`,
        params: { id, service: "one-time" },
      });
    },
    removeMenu(id) {
      this.pageData = this.pageData.filter((menu) => menu.id !== id);
    },
    searchItems(searchQuery) {
      if (searchQuery === "") {
        return;
      }
      const searchParams = `search=${searchQuery}`;
      this.loading = true;
      onetime
        .list(searchParams)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.pageData = data.data;
            this.from = data.from;
            this.to = data.to;
            this.total = data.total;
            this.pageSize = data.per_page;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.response.data.message);
        });
    },
    setMenuActive() {
      // const index = this.pageData.findIndex((item) => item.id == this.menuId);
      onetime
        .active(this.menuId)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.getMenus();
            this.shouldShow = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.shouldShow = false;
        });
    },
    publishMenu() {
      onetime
        .publish(this.menuId)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.getMenus();
            this.shouldShow = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.shouldShow = false;
        });
    },
    duplicate() {
      onetime
        .duplicate(this.menuId)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.getMenus();
            this.shouldShow = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.shouldShow = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.eden-meal--image {
  height: 36px;
  width: 36px;
  border-radius: 8px;
  background: #e9fbf2;
  margin-right: 20px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 8px;
  }

  +div {
    width: 70%;
  }
}

.working-hour {
  width: 100%;
}
</style>
